import React from 'react'
import Footer from '../components/footer'
import Header from '../components/header'
import { Link } from 'react-router-dom'

export default function ContactUs() {
  return (
    <>
      <Header />
      <section className="bg-half bg-light">
        <div className="home-center">
          <div className="home-desc-center">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12 text-center">
                  <div className="page-next-level">
                    <h4 className="title">Contact Us</h4>
                    <ul className="page-next d-inline-block bg-white shadow p-2 pl-4 pr-4 rounded mb-0">
                      <li><Link to="/" className="text-uppercase  text-dark">Home</Link>
                      </li>
                      <li>
                        <span className="text-uppercase text-primary ">Contact us</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
          </svg>
        </div>
      </div>

      <section className="section pb-0 contact">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="contact-detail">

                <div className="content mt-3">
                  <h4 className="title font-weight-bold">ELCOT SEZ - Salem</h4>
                  <p className="">G6, Jaggir Ammapalayam, Salem,<br />Tamil Nadu, India 636302.</p>
                  <a href="mailto:info@stinsoft.com" className="text-primary"> info@stinsoft.com</a><b />
                  <a href="tel:+918110040055" className="text-primary">+91 8110040055</a>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="contact-detail">

                <div className="content mt-3">
                  <h4 className="title font-weight-bold">Registered Office</h4>
                  <p className="">H38, TNHB Opp. Periyar University,Salem, <br /> TamilNadu, India 636011.
                  </p>
                  <a href="mailto:info@stinsoft.com" className="text-primary">info@stinsoft.com</a><br />
                  <a href="tel:+918110030055" className="text-primary">+91 8110030055</a>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="contact-detail">

                <div className="content mt-3">
                  <h4 className="title font-weight-bold">South Africa</h4>
                  <p className="">922, Moss Road Weltevredenpark,<br />Roodeport, Johannesburg 1079 South
                    Africa</p>
                  <a href="mailto:prabanand.rathnam@stinsoft.com"
                    className="text-primary">prabanand.rathnam@stinsoft.com</a><br />
                  <a href="tel:+152534-468-854" className="text-primary">+27795054372</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-100 mt-60">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
              <div className="pt-5 pb-5 p-4 bg-light shadow rounded">
                <h4>Get In Touch !</h4>
                <div className="custom-form mt-4">
                  <div id="message"></div>
                  <form method="post" action="php/contact.php" name="contact-form" id="contact-form">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group position-relative">
                          <label>Your Name <span className="text-danger">*</span></label>
                          <i className="mdi mdi-account ml-3 icons"></i>
                          <input name="name" id="name" type="text" className="form-control pl-5"
                            placeholder="First Name" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group position-relative">
                          <label>Your Email <span className="text-danger">*</span></label>
                          <i className="mdi mdi-email ml-3 icons"></i>
                          <input name="email" id="email" type="email" className="form-control pl-5"
                            placeholder="Your email" />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group position-relative">
                          <label>Comments</label>
                          <i className="mdi mdi-comment-text-outline ml-3 icons"></i>
                          <textarea name="comments" id="comments" rows="4" className="form-control pl-5"
                            placeholder="Your Message"></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 text-center">
                        <input type="submit" id="submit" name="send"
                          className="submitBnt btn btn-primary btn-block" value="Send Message" />
                        <div id="simple-msg"></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-lg-7 col-md-6 order-1 order-md-2">
              <img src="images/about/contact.png" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
