import React from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'

export default function HybridDevelopment() {
    return (
        <>
            <Header />
            <section className="bg-half-170 d-table w-100 it-home"
                style={{ backgroundImage: "url('/images/slides/white-bg1.jpg')", backgroundPositionX: 'center', backgroundPositionY: 'center' }}>
                 <div className=""></div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="title-heading">
                                <h2 className="fw-bold text-dark title-dark mt-2 mb-3">Hybrid Apps: Powering Your Business.</h2>
                                <p className="para-desc text-success">Revolutionize Your Business with Hybrid App Development:
                                    Harnessing the Power of Cross-Platform Technology</p>

                            </div>
                        </div>

                        <div className="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="/images/about/ios-app-developemnt.svg" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <div className="service-container">
                <section className="section">
                    <div className="container">
                        <div className="row align-items-center ios-section">
                            <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div>
                                    <div className="section-title">
                                        <h4 className="title mb-4">React-Native Development</h4>
                                    </div>
                                    <p>React Native is an open-source framework developed by Facebook that allows us to build
                                        cross-platform mobile applications for iOS and Android with a single codebase. With its
                                        rich set of features and excellent performance, React Native is quickly becoming a
                                        popular choice for businesses looking to develop mobile applications.
                                        <br />
                                        Our React Native Development Services cover the entire app development lifecycle, from
                                        ideation to post-launch maintenance and support. We work closely with our clients to
                                        understand their business requirements and create custom React Native mobile
                                        applications that meet their specific needs.
                                    </p>
                                    Our React Native Development Services include:

                                    <ul className="list-unstyled feature-list ">
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">
                                            Custom React Native Mobile App Development
                                        </span>
                                        </li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">
                                            React Native App Design and Development </span>
                                        </li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">
                                            React Native App Integration with Other Systems and Platforms
                                        </span>
                                        </li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">
                                            React Native App Maintenance and Support
                                        </span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div className="col-lg-5 col-md-6">
                                <img src="/images/about/application-development.svg" className="img-fluid ios-app" alt="" />
                            </div>
                            <div>

                                <div className="pl-3">
                                    Our React Native Development process includes:
                                    <ul className="list-unstyled feature-list ">
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">
                                            Ideation and Planning: Our team works closely with clients to understand their
                                            vision
                                            and objectives for the mobile application. We conduct market research and
                                            feasibility
                                            studies to identify the best strategies for achieving those objectives.
                                            those objectives.
                                        </span>
                                        </li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">
                                            Design and Development: Our team of experienced React Native developers uses the
                                            latest
                                            technologies and tools to create visually appealing, intuitive, and responsive
                                            mobile
                                            applications that meet the client's requirements.
                                            those objectives.
                                        </span>
                                        </li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">
                                            Quality Assurance: We rigorously test the mobile application to ensure that it
                                            meets the
                                            highest quality standards, including performance, user experience, and security.
                                            those objectives.
                                        </span>
                                        </li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">
                                            Deployment and Support: We work with clients to deploy the mobile application to
                                            the App
                                            Store and Google Play Store and provide ongoing maintenance and support services
                                            to
                                            ensure the app remains up-to-date and secure.
                                            those objectives.
                                        </span>
                                        </li>
                                    </ul>


                                    We are committed to delivering high-quality React Native Development Services that
                                    exceed our clients' expectations. Contact us today to learn how we can help you bring
                                    your mobile application idea to life!



                                </div>

                            </div>

                        </div>
                        <div className="clear"></div>
                        <div className="row align-items-center ios-section pt-3">
                            <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div>
                                    <div className="section-title">
                                        <h4 className="title mb-4">Flutter Development</h4>
                                    </div>
                                    <p>Flutter is an open-source framework developed by Google that allows us to build high-quality mobile applications for
                                        iOS and Android with a single codebase. With its excellent performance and fast development cycle, Flutter has
                                        quickly become a popular choice for businesses looking to develop mobile applications.
                                        <br />
                                        Flutter is an open-source framework for mobile app development that allows developers to build high-performance,
                                        cross-platform apps for Android and iOS with a single codebase. Flutter's key feature is its ability to create beautiful
                                        and responsive user interfaces with its customizable widgets.
                                    </p>
                                    Our React Native Development Services include:

                                    <ul className="list-unstyled feature-list ">
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">
                                            Flutter offers a rich set of pre-built widgets for developers to use in their apps. These widgets are customizable and
                                            can be used to build anything from simple buttons to complex animations. Flutter widgets are designed to work seamlessly
                                            with each other and provide a consistent look and feel across platforms.
                                        </span>
                                        </li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">
                                            Flutter's hot reload feature allows developers to see changes made to their code in real-time without the need to
                                            recompile the entire app. This means that developers can iterate quickly and make changes to their app without losing
                                            their place in the code. </span>
                                        </li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">
                                            Flutter allows developers to build apps for both Android and iOS with a single codebase. This means that developers can
                                            save time and resources by not having to build separate apps for each platform. Additionally, Flutter's customizable
                                            widgets provide a consistent user experience across platforms.
                                        </span>
                                        </li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">
                                            Flutter has a growing community of developers who contribute to its development and provide support to other developers.
                                            The Flutter community is active and vibrant, with many resources available online for developers to learn and grow their
                                            skills.
                                        </span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div className="col-lg-5 col-md-6">
                                <img src="/images/about/flutter.svg" className="img-fluid ios-app" alt="" />
                            </div>
                            <div className="pl-2">

                                Flutter is a powerful framework for mobile app development that offers many benefits, including fast development time,
                                cross-platform support, and customizable widgets. If you're looking to build a high-performance, cross-platform mobile
                                app, Flutter is definitely worth considering.
                            </div>

                        </div>
                    </div>
                </section>
            </div>

            <Footer />
        </>
    )
}
