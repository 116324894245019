import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import { Link } from 'react-router-dom'
// import $ from 'jquery';

// $(document).ready(function () {
//     Main.init();
//     Index.init();
// });

export default function Home() {
    return (
        <>
            <Header />
            <div className="navbar banner">
            </div>
            <section className="bg-half-170 d-table w-100 it-home"
                style={{ backgroundImage: "url('/images/slides/stinsoft.jpg')", backgroundPositionX: 'center', backgroundPositionY: 'center' }}>
                <div className=""></div>

                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="title-heading">
                                <h5 className="text-secondary">We at StinSoft</h5>
                                <h2 className="fw-bold text-dark title-dark mt-2 mb-3">Transforming Ideas into Reality with Technology </h2>
                                <p className="para-desc text-success">Your Partner in Digital Transformation through Software Development.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="/images/services/web-app.svg" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>

            <div className="container service-section">
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <div className="section-title pb-2">
                            <h4 className="title mb-4">Services</h4>
                            <p className=" para-desc mb-0 mx-auto">We Provides a wide range of Web Design and Development.
                                We don’t only do design and develop websites but we renovate them to influential business
                                channels. Web design is the ability of crafting and presentations of content.</p>
                        </div>
                    </div>
                </div>

                <div className="row our-services">

                    <div className="col-md-4 col-12 mt-5">
                        <div className="features text-center">
                            <div className="image position-relative d-inline-block">
                                <img src="images/icon/computer.svg" alt="" />
                            </div>

                            <div className="content mt-4">
                                <h4 className="title-2">Enterprise Cloud Solutions</h4>
                                <p className="">In internet savvy age to meet customer demand, online presence of your
                                    business is mandatory, we help you to establish your business online.</p>
                                <a href="services.html" className="text-primary">Learn More <i
                                    className="mdi mdi-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-12 mt-5">
                        <div className="features text-center">
                            <div className="image position-relative d-inline-block">
                                <img src="images/icon/currency.svg" alt="" />
                            </div>

                            <div className="content mt-4">
                                <h4 className="title-2">mobile apps</h4>
                                <p className="">We deliver Custom and Enterprise mobile solution across multiple platforms
                                    taking the advantage of technologies, standards and industry best practices.</p>
                                <a href="services.html" className="text-primary">Learn More <i
                                    className="mdi mdi-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-12 mt-5">
                        <div className="features text-center">
                            <div className="image position-relative d-inline-block">
                                <img src="images/icon/pen.svg" alt="" />
                            </div>

                            <div className="content mt-4">
                                <h4 className="title-2">innovative designs</h4>
                                <p className="">StinSoft understands that one of the most integral components of a
                                    company's web success is the design of their website. We aim to create companies ...</p>
                                <a href="services.html" className="text-primary">Learn More <i
                                    className="mdi mdi-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container we-offers">
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <div className="section-title pb-2">
                            <h4 className="title mb-4">Products</h4>
                            <p className=" para-desc mb-0 mx-auto">We mainly focus on the needs of our clients and their
                                satisfaction, we achieve this by accomplishing our commitment making it sure that we follow up
                                to date technologies and provide quality assurance to them.</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3 col-12 mt-4 pt-2 service">
                        <div className="text-center">
                            <div className="p-4 shadow products">
                                <Link to="/products">
                                    <img src="images/icon/graduation-hat.svg" height="100" className="mx-auto d-block" alt="" />
                                </Link>
                            </div>

                            <div className="mt-3">
                                <h4 className="title-2">Learning Management Solutions</h4>
                                <Link to="/products" className="text-primary">Learn More <i
                                    className="mdi mdi-chevron-right"></i></Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-12 mt-4 pt-2 service">
                        <div className="text-center">
                            <div className="p-4 shadow products">
                                <Link to="/products">
                                    <img src="images/icon/calendar.svg" height="100" className="mx-auto d-block" alt="" />
                                </Link>
                            </div>

                            <div className="mt-3">
                                <h4 className="title-2">Event Management System</h4>
                                <Link to="/products" className="text-primary">Learn More <i
                                    className="mdi mdi-chevron-right"></i></Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-12 mt-4 pt-2 service">
                        <div className="text-center">
                            <div className="p-4 shadow products">
                                <Link to="/products"><img src="images/icon/bed.svg" height="100" className="mx-auto d-block"
                                    alt="" /></Link>
                            </div>

                            <div className="mt-3">
                                <h4 className="title-2">Hotel Management Systems</h4>
                                <Link to="/products" className="text-primary">Learn More <i
                                    className="mdi mdi-chevron-right"></i></Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-12 mt-4 pt-2 service">
                        <div className="text-center">
                            <div className="p-4 shadow products">
                                <Link to="/products"><img src="images/icon/video.svg" height="100" className="mx-auto d-block"
                                    alt="" /></Link>
                            </div>

                            <div className="mt-3">
                                <h4 className="title-2">OTT</h4>
                                <Link to="/products" className="text-primary">Learn More <i
                                    className="mdi mdi-chevron-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="section-two bg-secondary">
                <div className="container">
                    <div className="row" id="counter">
                        <div className="col-md-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <p>We are moving boundaries and carry out this attitude into StinSoft. We concentrate on your cost
                                effective online lead generation to expand your business.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
