import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import { Link } from 'react-router-dom'

export default function Products() {
    return (
        <>
            <Header />
            <section className="bg-half bg-light">
                <div className="home-center">
                    <div className="home-desc-center">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-12 text-center">
                                    <div className="page-next-level">
                                        <h4 className="title">Products</h4>
                                        <ul className="page-next d-inline-block bg-white shadow p-2 pl-4 pr-4 rounded mb-0">
                                            <li><Link to="/" className="text-uppercase  text-dark">Home</Link>
                                            </li>
                                            <li>
                                                <span className="text-uppercase text-primary ">Products</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <div className="service-container">
                <section className="section">
                    <div className="container">
                        <div className="row align-items-center java-section">
                            <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="section-title">
                                    <h4 className="title mb-4">OTT - Audio / Video Streaming</h4>
                                    <p className="">Launch your own branded Multi-Screen Video-on-Demand (VOD) Platform in
                                        just 1-Click!

                                        Get everything you need to launch your own Multi-Screen OTT Video On Demand (VOD)
                                        Platform with Us! From deploying, hosting and managing your IT Infrastructure to
                                        complete VOD software solutions with Video CMS, HTML5 based Online Video Player,
                                        Hollywood Studio approved DRM, Transcoding / Encoding Servers, Subscriber, User
                                        Management, Reports & Analytics as well as building, managing and hosting of the Website
                                        and apps for Mobile and TVs.

                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-6">
                                <img src="images/about/ott.svg" className="img-fluid" alt="" />
                            </div>
                        </div>
                        <div className="row align-items-center java-section">
                            <div className="row">
                                <div className="col-md-4 col-12">
                                    <div className="features pt-4 pb-4">
                                        <span className="icon text-center rounded-pill text-primary mr-3 mt-2 float-left">
                                            <img src="images/icon/video.svg" alt="" />
                                        </span>
                                        <div className="content d-block overflow-hidden">
                                            <h4 className="title">Live Streaming</h4>
                                            <p className=" para mb-0">Start Live Streaming anything to everything
                                                instantly! Start streaming TV Channels, Sports, News & Events without any
                                                hassles, simply connect and Go-Live. Muvi takes care of everything else.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="features pt-4 pb-4">
                                        <span className="icon text-center rounded-pill text-primary mr-3 mt-2 float-left">
                                            <img src="images/icon/video.svg" alt="" />
                                        </span>
                                        <div className="content d-block overflow-hidden">
                                            <h4 className="title">Video On Demand</h4>
                                            <p className=" para mb-0">Launch a Multi-Screen OTT Platform like Netflix
                                                instantly! End-to-End OTT video solution includes Infrastructure, Video Player,
                                                CMS, Website, Mobile & TV Apps.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="features pt-4 pb-4">
                                        <span className="icon text-center rounded-pill text-primary mr-3 mt-2 float-left">
                                            <img src="images/icon/device.svg" alt="" />
                                        </span>
                                        <div className="content d-block overflow-hidden">
                                            <h4 className="title">Audio / Music Streaming</h4>
                                            <p className=" para mb-0">Launch an Audio / Music Streaming service like
                                                Spotify instantly! End-to-End solution includes everything from Infrastructure,
                                                CMS, Billing Engine, Website and Apps for Mobile Devices.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-5 col-md-6">
                                <img src="images/about/learning-management.svg" className="img-fluid" alt="" />
                            </div>

                            <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="section-title">
                                    <h4 className="title mb-4">Learning Management Solutions</h4>
                                    <p className="">The role of a Learning Management System varies depending on the
                                        organization’s objectives, online training strategy, and desired outcomes. However, the
                                        most common use for LMS software is to deploy and track online training initiatives.
                                        Typically, assets are uploaded to the Learning Management System, which makes them
                                        easily accessible for remote learners. In some cases, the LMS may even have built-in
                                        eLearning authoring tools that allow you to develop online training materials without
                                        additional third-party software.</p>
                                    <p className="event-modules">When looking to create successful customer training programs, LMS
                                        needs to be able to provide some of the following:</p>
                                    <ul className="list-unstyled feature-list ">
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">Friendly and easy to use interface</span></li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">Mobile learning compatibility</span></li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">Social learning capabilities</span></li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">Multi-tenancy support</span></li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">Localization/Multilanguage support</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="section-title">
                                    <h4 className="title mb-4">Event Management System</h4>
                                    <p className="">Event management system is used to manage all the activity related to
                                        event. In any event many service providers work simultaneously and it is very hard to
                                        manage these providers. It is also important for event organizer that he has all the
                                        contacts details of these service providers so that he can contact them any time to plan
                                        an event at given time. To manage all these activity we have developed this software. To
                                        get success in the event management business, user should have strong network contacts
                                        of service provider. These contacts are essentially providers of specific services who
                                        can be mobilized quickly to participate in any given event.</p>
                                    <p className="event-modules">Following are the main module in this system.</p>
                                    <ul className="list-unstyled feature-list ">
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">Customer Module.</span></li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">Event management.</span></li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">Sponsors management.</span></li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">Service Providers management.</span></li>

                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-6">
                                <img src="images/about/event-management.svg" className="img-fluid" alt="" />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-5 col-md-6">
                                <img src="images/about/hotel-management.svg" className="img-fluid" alt="" />
                            </div>

                            <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="section-title">
                                    <h4 className="title mb-4">Hotel Management System</h4>
                                    <p className="">A hotel management software - a software that streamlines the A-Z of
                                        hotel operations. It is a set of fully integrated modules that handle reservation, room
                                        operations, guest engagement, banqueting and so. Playing a significant role in
                                        increasing a hotel’s efficiency and revenue, the hotel management software has become an
                                        inseparable part of hotel management.</p>
                                    <ul className="list-unstyled feature-list ">
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">Room bookings</span></li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">Collection of e-payments</span></li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">Management of room inventory and allocation</span></li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">Reservation emails</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}
