
import { Route, Routes } from 'react-router';
import './App.css';
import Home from './layout/home';
import About from './layout/about';
import $ from "jquery";
import Products from './layout/products';
import ContactUs from './layout/contact-us';
import Careers from './layout/careers';
import NativeDevelopment from './layout/services/native';
import HybridDevelopment from './layout/services/hybrid';
import NodeJSPage from './layout/services/nodejs';
import SpringBootPage from './layout/services/spring-boot';



function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="about-us" element={<About />} />
      <Route path="products" element={<Products />} />
      <Route path="contact-us" element={<ContactUs />} />
      <Route path="careers" element={<Careers />} />
      <Route path="native" element={<NativeDevelopment/>} />
      <Route path="hybrid" element={<HybridDevelopment/>} />
      <Route path="nodejs" element={<NodeJSPage/>} />
      <Route path="spring-boot" element={<SpringBootPage/>} />
    </Routes>
  );
}

export default App;
