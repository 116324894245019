import React from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'

export default function NativeDevelopment() {
    return (
        <>
            <Header />
            <section className="bg-half-170 d-table w-100 it-home"
                style={{ backgroundImage: "url('/images/slides/white-bg1.jpg')", backgroundPositionX: 'center', backgroundPositionY: 'center' }}>
                <div className=""></div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="title-heading">
                                <h2 className="fw-bold text-dark title-dark mt-2 mb-3">Craft seamless mobile experiences<br />
                                    with Native Development.</h2>
                                <p className="para-desc text-success">Unleashing the Power of Native Mobile Development: Build
                                    Next-Level Apps for iOS and Android</p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="/images/about/mobile.svg" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <div className="service-container">
                <section className="section">
                    <div className="container">
                        <div className="row align-items-center ios-section">
                            <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div>
                                    <div className="section-title">
                                        <h4 className="title mb-4">IOS Application Development Services</h4>
                                    </div>
                                    <p>We provide a full range of IOS app development services to make your
                                        business ideas thrive. Our IOS development team offers services for different business
                                        domains and devices including iPhone, iPad, Apple Watch, and Apple TV development. We
                                        build both enterprise and consumer mobile apps for our clients. Our team of expert iOS
                                        app developers is dedicated
                                        to creating custom applications that meet your unique business needs.
                                        <br />
                                        Our iOS app development services cover the entire app development lifecycle, from
                                        ideation to post-launch
                                        maintenance and support. We specialize in developing native iOS applications that are
                                        optimized for performance,
                                        user experience, and security.

                                        Our team has extensive experience in developing iOS applications across various
                                        industries, including healthcare,
                                        finance, e-commerce, entertainment, and education. We work closely with our clients to
                                        understand their business
                                        requirements and deliver iOS apps that meet their specific needs.
                                        <br />
                                        Our iOS app development process includes:
                                    </p>
                                </div>

                            </div>
                            <div className="col-lg-5 col-md-6">
                                <img src="/images/about/ios-app.svg" className="img-fluid ios-app" alt="" />
                            </div>
                            <div>
                                <p>
                                    <ul className="list-unstyled feature-list ">
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">
                                            Ideation and Planning: Our team works closely with clients to understand their
                                            vision and objectives for the
                                            iOS app. We conduct market research and feasibility studies to identify the best
                                            strategies for achieving
                                            those objectives.
                                        </span>
                                        </li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">
                                            Design and Development: Our team of experienced iOS app developers uses the latest
                                            technologies and tools to
                                            create visually appealing, intuitive, and responsive iOS apps that meet the client's
                                            requirements.
                                        </span>
                                        </li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">

                                            Quality Assurance: We rigorously test the iOS app to ensure that it meets the
                                            highest quality standards,
                                            including performance, user experience, and security.
                                        </span>
                                        </li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">
                                            Deployment and Support: We work with clients to deploy the iOS app to the App Store
                                            and provide ongoing
                                            maintenance and support services to ensure the app remains up-to-date and secure.
                                        </span>
                                        </li>
                                    </ul>
                                    Our iOS app development services include:
                                    <ul className="list-unstyled feature-list ">
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">
                                            Custom iOS app development
                                        </span></li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">
                                            iOS app design and development
                                        </span></li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">
                                            iOS app integration with other systems and platforms
                                        </span></li>
                                        <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                            className="react-des">
                                            iOS app maintenance and support
                                        </span></li>
                                    </ul>
                                    We are committed to delivering high-quality iOS app development services that exceed our
                                    clients' expectations. Contact us today to learn how we can help you bring your iOS app idea to
                                    life!
                                </p>
                            </div>
                        </div>
                        <div className="row align-items-center android-section">
                            <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 pl-0">
                                <div className="section-title">
                                    <h4 className="title mb-4">Android Application Development Services</h4>
                                </div>
                                <p className="">We provide Android app development services for clients who want to
                                    reach more than two billion monthly active users on the Android platform. We build apps
                                    for multiple Android devices</p>
                                <p>
                                    Our team of expert Android app developers has a wealth of experience in developing
                                    high-performance, user-friendly,
                                    and secure Android applications for various industries, including healthcare, finance,
                                    e-commerce, entertainment,
                                    and education.
                                    <br />
                                    We offer end-to-end Android app development services that cover the entire app development
                                    lifecycle, from ideation
                                    to post-launch maintenance and support. Our team works closely with clients to understand
                                    their business
                                    requirements and create custom Android apps that meet their specific needs.
                                </p>
                            </div>
                            <div className="col-lg-5 col-md-6">
                                <img src="/images/about/android-app-developemnt.svg" className="img-fluid" alt="" />
                            </div>
                        </div>

                        Our Android app development process includes:
                        <ul className="list-unstyled feature-list ">
                            <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span className="react-des">
                                Ideation and Planning: We work closely with clients to understand their vision and
                                objectives for the Android app. We conduct market research and feasibility studies to
                                identify the best strategies for achieving those objectives.
                            </span></li>
                            <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span className="react-des">
                                Design and Development: Our team of experienced Android app developers uses the latest
                                technologies and tools to create visually appealing, intuitive, and responsive Android apps
                                that meet the client's requirements.
                            </span></li>
                            <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span className="react-des">
                                Quality Assurance: We rigorously test the Android app to ensure that it meets the highest
                                quality standards, including performance, user experience, and security.
                            </span></li>
                            <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span className="react-des">
                                Deployment and Support: We work with clients to deploy the Android app to the Google Play
                                Store and provide ongoing maintenance and support services to ensure the app remains
                                up-to-date and secure.
                            </span></li>
                        </ul>
                        Our Android app development services include:
                        <ul className="list-unstyled feature-list ">
                            <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span className="react-des">
                                Custom Android app development
                            </span></li>
                            <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span className="react-des">
                                Android app design and development
                            </span></li>
                            <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span className="react-des">
                                Android app integration with other systems and platforms
                            </span></li>
                            <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span className="react-des">
                                Android app maintenance and support
                            </span></li>
                        </ul>
                        <p>
                            As an offshore development company, we offer cost-effective Android app development services without
                            compromising on quality. Our team is dedicated to delivering high-quality Android app development
                            services that exceed our clients' expectations.

                            Contact us today to learn how we can help you bring your Android app idea to life!
                        </p>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}
