import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import { Link } from 'react-router-dom'

export default function About() {
  return (
    <>
        <Header/>
        <section className="bg-half bg-light">
        <div className="home-center">
            <div className="home-desc-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 text-center">
                            <div className="page-next-level">
                                <h4 className="title"> About us </h4>
                                <ul className="page-next d-inline-block bg-white shadow p-2 pl-4 pr-4 rounded mb-0">
                                    <li><Link to="/" className="text-uppercase  text-dark">Home</Link>
                                    </li>
                                    <li>
                                        <span className="text-uppercase text-primary ">About Us</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div className="position-relative">
        <div className="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <section className="section about-section">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                    <div className="position-relative">
                        <img src="images/about/about.svg" className="rounded img-fluid mx-auto d-block" alt=""/>
                    </div>
                </div>

                <div className="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
                    <div className="section-title ml-lg-4">
                        <p>
                            <span className="text-primary">StinSoft</span> is a software development company that specializes in developing custom software
                            solutions for businesses of all sizes. We have been in business for over 10 years and have a
                            team of experienced developers who are passionate about creating innovative software
                            solutions that meet the unique needs of our clients.
                        </p>
                        <p>
                            We have worked with clients in a variety of industries, including healthcare, retail,
                            manufacturing, and logistics. No matter what industry you are in, we can create a custom
                            software solution that will help your business run more efficiently and effectively.
                        </p><p>
                            If you are looking for a partner to help you develop custom software solutions, then please
                            contact us today. We would be happy to discuss your project with you and provide you with a
                            proposal outlining our services.
                        </p>
                       
                        <p className="">Our wide range of services includes Website Application Development,
                            Desktop Applications, Mobile Application Development & Designs. Our Goal is to keep our
                            clients ahead of their competition and to create lasting relationships. We are committed to
                            our clients' success. please mail to <span className="text-primary">info@stinsoft.com</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="section border-bottom why-choose-section">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 text-center">
                    <div className="section-title mb-60">
                        <h4 className="title mb-4">Why Choose Us?</h4>
                        <p className=" para-desc mb-0 mx-auto">We bring innovation, intelligence and deep industry
                            experience together with leading technologies from our ecosystem partners to help you
                            reinvent your business as an intelligent enterprise.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row align-items-center why-choose">
                <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div className="section-title ml-lg-5">
                        <h4 className="title mb-4">Customer Driven Result</h4>
                        <p className="">Customer satisfaction is the driving force behind our success. Our team
                            brings rich technical expertise and passion for quality to each customer relationship.
                            Working in partnership with our client, we develop each project with maximum scalability and
                            usability in mind to enhance customer experience.</p>
                    </div>
                </div>
                <div className="col-lg-5 col-md-6">
                    <img src="images/about/customer.svg" className="rounded img-fluid mx-auto d-block" alt="" />
                </div>
            </div>
            <div className="row align-items-center why-choose">
                <div className="col-lg-5 col-md-6">
                    <img src="images/about/cost-effective.svg" className="rounded img-fluid mx-auto d-block" alt="" />
                </div>
                <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div className="section-title ml-lg-5">
                        <h4 className="title mb-4">Cost Effective</h4>
                        <p className="">We provide highly cost effective solutions to our clients on requirement
                            basis. Our offshore development center based out in Salem, India which will allows us to
                            provide high quality services at a lower cost to our clients.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row align-items-center why-choose">
                <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div className="section-title ml-lg-5">
                        <h4 className="title mb-4">Technical Expertise</h4>
                        <p className="">At StinSoft we always try to stay abreast of the current technologies and
                            trends to ensure that our website and web applications work properly and smoothly on all
                            browsers and operating systems.</p>
                    </div>
                </div>
                <div className="col-lg-5 col-md-6">
                    <img src="images/about/technical-expertise.svg" className="rounded img-fluid mx-auto d-block" alt=""/>
                </div>
            </div>
            <div className="row align-items-center why-choose">
                <div className="col-lg-5 col-md-6">
                    <img src="images/about/proven-methodology.svg" className="rounded img-fluid mx-auto d-block" alt=""/>
                </div>
                <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div className="section-title ml-lg-5">
                        <h4 className="title mb-4">Proven Methodology</h4>
                        <p className="">Our team approach allows for maximum flexibility with increased customer
                            engagement to deliver a product as per specifications. The best way to ensure customer
                            satisfaction is by integrating customer feedback at every stage of the development cycle. We
                            do so by following the agile methodology with an accelerated delivery time and reduced
                            costs.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  
        <Footer/>
    </>
  )
}
