import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div>
            <div class="position-relative">
                <div class="shape overflow-hidden text-light">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                            <Link className="logo-footer text-dark" to="/"><img src="images/stinsoft/logo.png" className="logo-img"
                                alt="" /></Link>
                            <p className="mt-4 ">StinSoft is started by young ambitious people to help you to enhance your
                                business. We will do our best to provide the best product, on schedule, in a fair note.</p>
                            <ul className="list-unstyled social-icon social mb-0 mt-4">
                                <li className="list-inline-item"><a href="https://www.facebook.com/stinsoft" target='_blank' className="rounded"><i
                                    className="mdi mdi-facebook" title="Facebook"></i></a></li>
                                <li className="list-inline-item"><a href="https://twitter.com/stinsoft" target='_blank' className="rounded"><i
                                    className="mdi mdi-twitter" title="Twitter"></i></a></li>
                            </ul>
                        </div>

                        <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <h4 className="text-dark footer-head">Company</h4>
                            <ul className="list-unstyled footer-list mt-4">
                                <li><Link to="/about-us" className=""><i className="mdi mdi-chevron-right mr-1"></i>
                                    About us</Link></li>
                                <li><Link to="/services" className=""><i className="mdi mdi-chevron-right mr-1"></i>
                                    Services</Link></li>
                                <li><Link to="/products" className=""><i className="mdi mdi-chevron-right mr-1"></i>
                                    Products</Link></li>
                                <li><Link to="/careers" className=""><i className="mdi mdi-chevron-right mr-1"></i>
                                    Careers</Link></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <h4 className="text-dark footer-head">Usefull Links</h4>
                            <ul className="list-unstyled footer-list mt-4">
                                <li><Link to="/terms" className=""><i className="mdi mdi-chevron-right mr-1"></i>
                                    Terms of Services</Link></li>
                                <li><Link to="/disclaimer" className=""><i className="mdi mdi-chevron-right mr-1"></i>
                                    Disclaimer</Link></li>
                                <li><Link to="/contact-us" className=""><i className="mdi mdi-chevron-right mr-1"></i>
                                    Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <h4 className="text-dark footer-head">Contact us if you have any Question?</h4>
                            <ul className="media-icon list-unstyled">
                                <li>Email : <a href="mailto:info@stinsoft.com"><b>info@stinsoft.com</b></a>
                                </li>
                                <li>Phone : <a href="tel:+91 8110040055"><b>+91 8110040055</b></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>

            <footer className="footer footer-bar">
                <div className="container text-center">
                    <div className="row align-items-center">
                        <div className="col-sm-12 text-center">
                            <p className="mb-0 ">Copyright © 2021 by StinSoft Technologies Pvt. Ltd. All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>

            <a href="#" className="back-to-top rounded text-center" id="back-to-top">
                <i className="mdi mdi-chevron-up d-block"> </i>
            </a>
        </div>
    )
}
