import React from 'react'
import { Link } from 'react-router-dom'

export default function Header() {
  return (
    <header id="topnav" className="defaultscroll sticky">
        <div className="bottomnav-bar">
            <div className="container">
                <div>
                    <a className="logo" href="/"><img src="images/stinsoft/logo.png" className="logo-img" alt="" /></a>
                </div>
                <div className="menu-extras">
                    <div className="menu-item">
                        <a className="navbar-toggle">
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </a>
                    </div>
                </div>
                <div id="navigation">
                    <ul className="navigation-menu">
                        <li><Link to="/">Home</Link></li>
                        <li className="has-submenu parent-parent-menu-item">
                            <a href="#">Services</a><span className="menu-arrow"></span>
                            <ul className="submenu">
                                <li className="has-submenu parent-menu-item"><a href="#"> Mobile Application</a><span className="submenu-arrow"></span>
                                    <ul className="submenu">
                                        <li><Link to="/native" className="sub-menu-item">Native iOS and Android</Link></li>
                                        <li><Link to="/hybrid" className="sub-menu-item">Hybrid Solutions</Link></li>
                                    </ul>  
                                </li>
                                <li className="has-submenu parent-menu-item"><a href="#"> Full Stack</a><span className="submenu-arrow"></span>
                                    <ul className="submenu">
                                        <li><Link to="/nodejs" className="sub-menu-item">NodeJs</Link></li>
                                        <li><Link to="/spring-boot" className="sub-menu-item">Spring Boot</Link></li>
                                    </ul>  
                                </li>
                                <li className="has-submenu parent-menu-item"><a href="#"> QA &amp; Testing</a><span className="submenu-arrow"></span>
                                    <ul className="submenu">
                                        <li><Link to="/manual-testing" className="sub-menu-item">Manual Testing</Link></li>
                                        <li><Link to="/automated-testing" className="sub-menu-item">Automated Testing</Link></li>
                                    </ul>  
                                </li>
                                {/* <li className="has-submenu parent-menu-item"><a href="#"> Design Solutions</a><span className="submenu-arrow"></span>
                                    <ul className="submenu">
                                        <li><a href="/ui-ux" className="sub-menu-item">UI &amp; UX Designs</a></li>
                                        <li><a href="/marketing-material" className="sub-menu-item">Marketing Materials</a></li>
                                    </ul>  
                                </li>  */}
                            </ul>
                        </li>
                        <li><Link to="/products">Products</Link></li>
                        <li><Link to="/careers">Careers</Link></li>
                        <li>
                            <Link to={"/about-us"}>About Us</Link>
                            {/* <a href="about-us">About Us</a> */}
                        </li>
                        <li><Link to="/contact-us">Contact Us</Link></li>
                    </ul>

                </div>
            </div>
        </div>
    </header>
  )
}
