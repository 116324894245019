import React from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'

export default function SpringBootPage() {
    return (
        <>
            <Header />
            <section className="bg-half-170 d-table w-100 it-home"
                style={{ backgroundImage: "url('/images/slides/white-bg1.jpg')", backgroundPositionX: 'center', backgroundPositionY: 'center' }}>
                <div className=""></div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="title-heading">
                                <h2 className="fw-bold text-dark title-dark mt-2 mb-3">Efficient and Reliable Web Development with
                                    Spring Boot: Build Scalable and High-Performing Applications.</h2>
                                <p className="para-desc text-success">Transform Your Full Stack Application Experience</p>

                            </div>
                        </div>

                        <div className="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src="/images/services/web-app-2.svg" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <div className="service-container">
                <section className="section">
                    <div className="container">
                        <div className="row align-items-center ios-section">
                            <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div>
                                    <div className="section-title">
                                        <h4 className="title mb-4">Java(Spring Boot) - Full Stack Development</h4>
                                    </div>
                                    <p className="text-left">If you're looking for a development team to build your next Spring Boot
                                        project, you've
                                        come to the right place. At our company, we specialize in helping companies bring their
                                        ideas to life using the latest technologies.
                                        <br />
                                        When it comes to Spring Boot development, we have a lot of experience to offer. We've
                                        worked on a wide range of projects, from small web apps to enterprise-level
                                        applications. No matter what your project entails, we have the skills and experience
                                        necessary to get the job done right.
                                        <br />
                                        If you're not familiar with Spring Boot, it's a Java-based framework that makes it easy
                                        to create stand-alone, production-grade Spring-powered applications. It's designed to
                                        get you up and running as quickly as possible, with minimal configuration.
                                        <br />

                                    </p>

                                </div>

                            </div>
                            <div className="col-lg-5 col-md-6">
                                <img src="/images/about/java-web-development-service.svg" className="img-fluid ios-app" alt="" />
                            </div>
                            <div className="pl-3">
                                <p>
                                    One of the benefits of working with our company is that we're always up-to-date on the
                                    latest technologies. We keep our skills sharp so that we can offer our clients the best
                                    possible service. When it comes to Spring Boot development, we always make sure we're
                                    using the latest version so that we can take advantage of all the latest features and
                                    improvements.
                                    <br />
                                    We understand that every client and project is different. That's why we take a
                                    personalized approach to every project we work on. We'll work with you to understand
                                    your specific needs and requirements so that we can tailor our services to best suit
                                    your needs.
                                    <br />
                                    If you're interested in learning more about our Spring Boot development services, or if
                                    you have any questions, we invite you to contact us today. We'll be happy to provide you
                                    with a free consultation and answer any of your questions.
                                </p>
                                Here are some of the reasons why you should choose us for your Spring Boot development needs:

                                <ul className="list-unstyled feature-list">
                                    <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                        className="react-des">
                                        We have a team of experienced and certified Spring Boot developers.

                                    </span>
                                    </li>
                                    <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                        className="react-des">
                                        We offer competitive rates for our Spring Boot development services.
                                    </span>
                                    </li>
                                    <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                        className="react-des">
                                        We use the latest tools and technologies for our Spring Boot development projects.
                                    </span>
                                    </li>
                                    <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                        className="react-des">
                                        We follow a strict quality assurance process to deliver high-quality Spring Boot development
                                        services.
                                    </span>
                                    </li>
                                    <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                        className="react-des">
                                        We provide timely and responsive customer support.
                                    </span>
                                    </li>
                                    <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                        className="react-des">
                                        We have a proven track record of successful Spring Boot development projects.
                                    </span>
                                    </li>
                                    <li><i className="mdi mdi-checkbox-marked-circle text-secondary h4 mr-2"></i><span
                                        className="react-des">
                                        We offer a free consultation to discuss your specific Spring Boot development needs.
                                    </span>
                                    </li>
                                </ul>
                                <p>
                                    If you're looking for a reliable, experienced, and affordable Spring Boot development company,
                                    contact us today to learn more about our services.
                                </p>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}
