import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import { Link } from 'react-router-dom'

export default function Careers() {
  return (
    <>
        <Header/>
        <section className="bg-half bg-light">
        <div className="home-center">
            <div className="home-desc-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 text-center">
                            <div className="page-next-level">
                                <h4 className="title">Careers</h4>
                                <ul className="page-next d-inline-block bg-white shadow p-2 pl-4 pr-4 rounded mb-0">
                                    <li><Link to="/" className="text-uppercase text-dark">Home</Link>
                                    </li>
                                    <li>
                                        <span className="text-uppercase text-primary ">Careers</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div className="position-relative">
        <div className="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>

    <section className="section careers">
        <div className="container cus-development">
            <div className="row justify-content-center">
                <div className="col-12 text-center">
                    <div className="section-title">
                        <h4 className="title mb-4">Empowering. Creative. Supportive</h4>
                        <p className=" para-desc mx-auto mb-0">We welcome forward-thinking individuals who share
                            our vision and can contribute to our success. Review the below web related jobs for current
                            openings. Kindly send us your resume on <span className="text-primary ">info@stinsoft.com</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <section>
            

        </section>


    </section>
        <Footer/>
    </>
  )
}
